"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicLands = exports.boardTypes = exports.colorFields = exports.CARD_STATUSES = exports.numFields = exports.allFields = exports.DefaultElo = exports.COLOR_CATEGORIES = void 0;
exports.isNumField = isNumField;
exports.isColorField = isColorField;
exports.COLOR_CATEGORIES = [
    'White',
    'Blue',
    'Black',
    'Red',
    'Green',
    'Colorless',
    'Multicolored',
    'Hybrid',
    'Lands',
];
exports.DefaultElo = 1200;
exports.allFields = [
    'name',
    'oracle',
    'mv',
    'mana',
    'type',
    'set',
    'tag',
    'status',
    'finish',
    'price',
    'priceFoil',
    'priceEur',
    'priceTix',
    'elo',
    'power',
    'toughness',
    'loyalty',
    'rarity',
    'legality',
    'artist',
    'is',
    'color',
    'colorIdentity',
];
exports.numFields = [
    'mv',
    'price',
    'priceFoil',
    'priceEur',
    'priceTix',
    'elo',
    'power',
    'toughness',
    'loyalty',
    'rarity',
    'legality',
];
function isNumField(field) {
    return exports.numFields.includes(field);
}
exports.CARD_STATUSES = ['Not Owned', 'Ordered', 'Owned', 'Premium Owned', 'Proxied', 'Borrowed'];
exports.colorFields = ['color', 'colorIdentity'];
function isColorField(field) {
    return exports.colorFields.includes(field);
}
exports.boardTypes = ['mainboard', 'maybeboard'];
exports.BasicLands = ['Plains', 'Island', 'Swamp', 'Mountain', 'Forest', 'Waste'];
