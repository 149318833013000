"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPhyrexianManaSymbol = exports.PhyrexianManaSymbols = exports.isGenericHybridManaSymbol = exports.isHybridManaSymbol = exports.HybridManaSymbols = exports.isManaSymbol = exports.ManaSymbols = void 0;
const typeGuards_1 = require("../util/typeGuards");
exports.ManaSymbols = ['W', 'U', 'B', 'R', 'G', 'C'];
exports.isManaSymbol = (0, typeGuards_1.createTypeGuard)(exports.ManaSymbols);
exports.HybridManaSymbols = [
    'W-U',
    'U-B',
    'B-R',
    'R-G',
    'G-W',
    'W-B',
    'U-R',
    'B-G',
    'R-W',
    'G-U',
    'C-W',
    'C-U',
    'C-B',
    'C-R',
    'C-G',
];
exports.isHybridManaSymbol = (0, typeGuards_1.createTypeGuard)(exports.HybridManaSymbols);
const isGenericHybridManaSymbol = (value) => {
    if (typeof value !== 'string')
        return false;
    return /^[0-9]+-[WUBRGC]$/.test(value);
};
exports.isGenericHybridManaSymbol = isGenericHybridManaSymbol;
exports.PhyrexianManaSymbols = [
    'W-P',
    'U-P',
    'B-P',
    'R-P',
    'G-P',
    'C-P',
    'G-W-P',
    'R-G-P',
    'R-W-P',
    'G-U-P',
];
exports.isPhyrexianManaSymbol = (0, typeGuards_1.createTypeGuard)(exports.PhyrexianManaSymbols);
